<template>
    <div class="container">
        <div class="info">
        
        <h3 class="name">
            {{a3user.id}}
          <br />
        </h3>
      </div>
    </div>
  </template>
  
  <script>
  //import Vue from 'vue';
  import { Toast } from "vant";
  import { mapMutations, mapGetters } from "vuex";
  import { LoginByWXForA3 } from "@/api/user";
  import { GetAppId } from "@/api/wxuser";
  
  
  
  export default {
    name: "vip",
    data() {
      return {
        appid: "",
        code: "",
        a3user:{}
      };
    },
    activated() {
      this.initData();
      this.CheckWenXin();
    },
    created() {
      this.initData();
      this.CheckWenXin();
    },
  
    methods: {
      async initData() {
        //this.code = this.$route.query.code;
        var queryString = location.search;
        // Further parsing:
        let params = new URLSearchParams(queryString);
        this.code = params.get("code");
      },
  
      async CheckWenXin() {
          if (this.code == null || this.code == ""|| this.code == undefined) {
            //await this.GetAppId();
            if (
              this.appid != "" &&
              this.appid != undefined &&
              this.appid != null
            ) {
              window.location.href =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                this.appid +
                "&redirect_uri=" +
                encodeURIComponent(window.location.href) +
                "&response_type=code&scope=snsapi_base#wechat_redirect";
            }
            else{
                Toast("获取AppId失败");
            }
  
 
            
          } else {
            const para = {code:  this.code };
            const opendata=  await LoginByWXForA3(para);
            if (opendata.data.success) {
                this.a3user=opendata.data.data;
                Toast("微信登录成功");
            }
            else{
                Toast("微信登录失败");
            }
          }
        
      },
  
      async GetAppId() {
        try {
          const data = await GetAppId();
          if (data.data.success) {
            this.appid = data.data.data;
          } else {
            Toast(data.data.errorMessage);
          }
        } catch (error) {
          console.log(error);
        }
      },
      
      ...mapMutations(["updateUser", "rememberUser"]),
  
      ...mapGetters(["getRememberUser"]),
    },
  
    watch: {
      $route(to, from) {
        //监听路由是否变化
        if (to.query != from.query) {
          this.initData();
        }
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .btn-box {
    padding: 20px;
  }
  </style>
  